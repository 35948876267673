import React, { useEffect } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import loadable from "@loadable/component";

import "./App.scss";
// import WalletCard from "./components/WalletCard";
// import ResumePage from './components/Resume';
// import EditBasicInformation from "./components/Resume/EditBasicInformation";
// import EditEducation from "./components/Resume/EditEducation";
// import EditWorkExperience from "./components/Resume/EditWorkExperience";
// import EditSkillLanguage from "./components/Resume/EditSkillLanguage";
// import ExportCV from "./components/Resume/exportCV";
// import EditCredentials from "./components/Resume/EditCertificates";
// import EditObjective from "./components/Resume/EditObjective";
// import EditSocial from "./components/Resume/EditSocial";
// import Login from "./components/Login";
// import Dashboard from "./components/BackOffice/Dashboard";
// import RequestPage from "./components/BackOffice/AI_Advisor/RequestPage";
// import AIAdvisor from "./components/BackOffice/AI_Advisor";
// import Authority from "./components/BackOffice/Authority";
// import Overall from "./components/BackOffice/Overall";
// import Users from "./components/BackOffice/Users";
// import Templates from "./components/BackOffice/Templates";
// import LoginRedirectCallBack from "./components/Login/LoginRedirectCallBack";
// import NavBar from "./components/BackOffice/NavBar";
// import BannerAlert from "./components/BackOffice/BannerAlert";
import { WebSocketContextProvider } from "./components/WebSocket/WebSocket";
import { UserContextProvider } from "./components/Context/UserContext";
import Constants from "./utils/Constants";

const Exit = loadable(() => import("./components/Exit"));
const WalletCard = loadable(() => import("./components/WalletCard"));
const ResumePage = loadable(() => import("./components/Resume"));
const EditBasicInformation = loadable(() =>
  import("./components/Resume/EditBasicInformation")
);
const EditEducation = loadable(() =>
  import("./components/Resume/EditEducation")
);
const EditWorkExperience = loadable(() =>
  import("./components/Resume/EditWorkExperience")
);
const EditSkillLanguage = loadable(() =>
  import("./components/Resume/EditSkillLanguage")
);
const ExportCV = loadable(() => import("./components/Resume/exportCV"));
const EditCredentials = loadable(() =>
  import("./components/Resume/EditCertificates")
);
const EditObjective = loadable(() =>
  import("./components/Resume/EditObjective")
);
const EditSocial = loadable(() => import("./components/Resume/EditSocial"));
const Login = loadable(() => import("./components/Login"));
const Dashboard = loadable(() => import("./components/BackOffice/Dashboard"));
const RequestPage = loadable(() =>
  import("./components/BackOffice/AI_Advisor/RequestPage")
);
const AIAdvisor = loadable(() => import("./components/BackOffice/AI_Advisor"));
const JobPosting = loadable(() => import("./components/JobPosting"));
const JobPostingDetail = loadable(() => import("./components/JobPosting/JobPostingDetail"));
const ApplicantConfirmation = loadable(() => import("./components/JobPosting/ApplicantConfirmation"));
const Filter = loadable(() => import("./components/JobPosting/Filter"));
const Authority = loadable(() => import("./components/BackOffice/Authority"));
const Overall = loadable(() => import("./components/BackOffice/Overall"));
const Users = loadable(() => import("./components/BackOffice/Users"));
const ResumeUsers = loadable(() => import("./components/BackOffice/ResumeUsers"));
const Templates = loadable(() => import("./components/BackOffice/Templates"));
const LoginRedirectCallBack = loadable(() =>
  import("./components/Login/LoginRedirectCallBack")
);
const NavBar = loadable(() => import("./components/BackOffice/NavBar"));
const BannerAlert = loadable(() =>
  import("./components/BackOffice/BannerAlert")
);

const LeaveStatus = loadable(() => import("./components/AnnualLeave/LeaveStatus"));
const LeaveRequest = loadable(() => import("./components/AnnualLeave/LeaveRequest"));
const LeaveHistory = loadable(() => import("./components/AnnualLeave/LeaveHistory"));
const LeaveView = loadable(() => import("./components/AnnualLeave/LeaveView"));
const StudioUsers = loadable(() => import("./components/BackOffice/StudioUsers"));

// const { WebSocketContextProvider } = loadable(() =>
//   import("./components/WebSocket/WebSocket")
// );
// const { UserContextProvider } = loadable(() =>
//   import("./components/Context/UserContext")
// );

function App() {
  const [searchParams] = useSearchParams();

  const location = useLocation();
  // Check if the current path matches any of the BackOffice's paths
  const isBackOfficePath = location.pathname.includes("admin");


  useEffect(() => {
    let token = searchParams.get("token");
    if (token) {
      localStorage.setItem(Constants.LOCAL_STORAGE.AUTHORIZATION, token);
    }
  }, [searchParams]);

  return (
    <div>
      <Routes>
        <Route path="/exit" element={<Exit />} />
        <Route path="/png/:cardId" element={<WalletCard />} />
        <Route path="/pdf/:cardId" element={<WalletCard />} />
        <Route path="/cards/:cardId" element={<WalletCard />} />

        {/*Already moved sources to careermon_resume_web repository, they need to be removed in the future*/}
        <Route path="/jobPostings">
          <Route path="" element={<JobPosting/>}/>
          <Route path=":id" element={<JobPostingDetail />} />
          <Route path="applicantConfirm" element={<ApplicantConfirmation />} />
          <Route path="filter" element={<Filter />} />
        </Route>
        <Route path="/resume">
          <Route path="" element={<ResumePage />} />
          <Route
            path="editBasicInformation"
            element={<EditBasicInformation />}
          />
          <Route path="editEducation" element={<EditEducation />} />
          <Route path="editWorkExperiences" element={<EditWorkExperience />} />
          <Route path="editSkillLanguage" element={<EditSkillLanguage />} />
          <Route path="editCredentials" element={<EditCredentials />} />
          <Route path="editObjective" element={<EditObjective />} />
          <Route path="editSocials" element={<EditSocial />} />
          <Route path="exportCV" element={<ExportCV />} />
        </Route>

        {/*Already moved sources to careermon_hr_web repository, they need to be removed in the future*/}
        <Route path="/annualLeave">
          <Route path="leaveStatus" element={<LeaveStatus />} />
          <Route path="leaveRequest/:topicId" element={<LeaveRequest />} />
          <Route path="leaveView" element={<LeaveView />} />
          <Route path="leaveHistory/:topicId" element={<LeaveHistory />} />
        </Route>
      </Routes>
      {isBackOfficePath && (
        <UserContextProvider>
          <WebSocketContextProvider>
            <NavBar />
            <Routes>
              <Route path="/admin">
                <Route path="" element={<Login />} />
                <Route
                  path="webLoginCallback"
                  element={<LoginRedirectCallBack />}
                />
                <Route path="users" element={<Users />} />
                <Route path="resumeUsers" element={<ResumeUsers />} />
                <Route path="aiContent" element={<RequestPage />} />
                <Route path="dashBoard" element={<Dashboard />} />
                <Route path="templates" element={<Templates />} />
                <Route path="aiAdvisor" element={<AIAdvisor />} />
                <Route path="overall" element={<Overall />} />
                <Route path="authority" element={<Authority />} />
                <Route path="studioUsers" element={<StudioUsers />} />
              </Route>
            </Routes>
            <BannerAlert />
          </WebSocketContextProvider>
        </UserContextProvider>
      )}
    </div>
  );
}

export default App;
